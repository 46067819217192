import React from "react";
import { createRoot, hydrateRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";

import './fonts/Maya.ttf'

import { Provider } from "react-redux";
import { store } from "./redux/store";
import App from "./App";

const container = document.getElementById("root");
if(container.hasChildNodes()) {
  hydrateRoot(container, 
    <BrowserRouter>
      <Provider store={store}>
        <App />
      </Provider>
    </BrowserRouter>
  )
} else {
  createRoot(container).render(
    <BrowserRouter>
      <Provider store={store}>
        <App />
      </Provider>
    </BrowserRouter>
  )
}
