import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { setSearchValue } from "../redux/slices/searchSlice";

import "./style.css";

import searchIcon from "./../img/avif/search.avif";
import closeIcon from "./../img/avif/close.avif";

export const Search = () => {
  const dispatch = useDispatch();
  const searchValue = useSelector((state) => state.searchValue.searchValue);

  return (
    <div className="search__box">
      <input
        value={searchValue}
        onChange={(event) => dispatch(setSearchValue(event.target.value))}
        className="search__input"
        placeholder="Поиск по названию"
      />
      <div className="search__icon">
        <img src={searchIcon} alt = 'лупа' />
      </div>
      {searchValue && (
        <div
          className="close__icon"
          onClick={() => dispatch(setSearchValue(""))}
        >
          <img src={closeIcon} alt = 'крестик' />
        </div>
      )}
    </div>
  );
};
