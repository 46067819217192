import vishnja_shokolad from "./img/item/Мусовые пироженые/Вишня в шоколаде.jpg";
import klubnika_cakes from "./img/item/Мусовые пироженые/Клубничное.jpg";
import lemongrass from "./img/item/Мусовые пироженые/Лимон-Лемонграсс.jpg";
import malina_cakes from "./img/item/Мусовые пироженые/Малиновое.jpg";
import ekzotic from "./img/item/Мусовые пироженые/Экзотик.jpg";
import klubnika_implosive_cakes from "./img/item/Мусовые пироженые/Клубничный взрыв.jpg";

export const mussCakesList = {
  meta: {
    title: "ДжоКонди | Пироженные",
    description: "Пироженные",
    keywords:
      "вишня, шоколад, клубника, лимон, лемонграсс, малина, экзотик, клубничный взрыв",
  },
  productList: [
    {
      id: "cakes1",
      groupProduct: "Муссовое пироженое",
      nameProduct: '"Вишня в шоколаде"',
      img: vishnja_shokolad,
      desc: [
        "Шоколадно-кофейное сабле",
        "Брауни с грецким орехом",
        "Взбитый шоколадный ганаш",
      ],
      price: 700,
      unit: "шт.",
      minCount: 3, // Минимальное кол-во
      stepCount: 1, // Шаг прибавления
      discount: 0, //Скидка в %
    },
    {
      id: "cakes2",
      groupProduct: "Муссовое пироженое",
      nameProduct: '"Клубничное"',
      img: klubnika_cakes,
      desc: ["Песочное сабле", "Компоте манго-маракуйя"],
      price: 530,
      unit: "шт.",
      minCount: 3, // Минимальное кол-во
      stepCount: 1, // Шаг прибавления
      discount: 0, //Скидка в %
      hit: true,
    },
    {
      id: "cakes3",
      groupProduct: "Муссовое пироженое",
      nameProduct: '"Лимон-Лемонграсс"',
      img: lemongrass,
      desc: [
        "Шоколадно-кофейное сабле",
        "Брауни с грецким орехом",
        "Взбитый шоколадный ганаш",
      ],
      price: 1100,
      unit: "шт.",
      minCount: 3, // Минимальное кол-во
      stepCount: 1, // Шаг прибавления
      discount: 0, //Скидка в %
    },
    {
      id: "cakes4",
      groupProduct: "Муссовое пироженое",
      nameProduct: '"Малиновое"',
      img: malina_cakes,
      desc: ["Песочное сабле", "Компоте манго-маракуйя"],
      price: 490,
      unit: "шт.",
      minCount: 3, // Минимальное кол-во
      stepCount: 1, // Шаг прибавления
      discount: 0, //Скидка в %
      hit: true,
    },
    {
      id: "cakes5",
      groupProduct: "Муссовое пироженое",
      nameProduct: '"Экзотик"',
      img: ekzotic,
      desc: [
        "Шоколадно-кофейное сабле",
        "Брауни с грецким орехом",
        "Взбитый шоколадный ганаш",
      ],
      price: 490,
      unit: "шт.",
      minCount: 3, // Минимальное кол-во
      stepCount: 1, // Шаг прибавления
      discount: 0, //Скидка в %
      hit: true,
    },
    {
      id: "cakes6",
      groupProduct: "Муссовое пироженое",
      nameProduct: '"Клубничный взрыв"',
      img: klubnika_implosive_cakes,
      desc: [
        "Ванильный бисквит",
        "Хрустящий слой",
        "Компот клубника",
        "Клубнично-ванильный мусс",
        "Ягодная глазурь",
      ],
      price: 470,
      unit: "шт.",
      minCount: 3, // Минимальное кол-во
      stepCount: 1, // Шаг прибавления
      discount: 0, //Скидка в %
    },
  ],
};
