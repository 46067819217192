import "./style.css";
import Footer from "../../../Footer";
import { Helmet } from "react-helmet";
import { DecorProduct } from "../DecorProduct";
import { decorationList } from "../../../dataDecor/mainDecor";

export const DecorPage = () => {
  return (
    <div className="container wrap">
      <section className="decor__item">
        <Helmet>
          <title>ДжоКонди | Оформление</title>
          <meta name="description" content="Оформление тортов" />
          <meta
            name="keywords"
            content="базовое, леденцами из изомальта, шоколадные шарики, вафельная бумага,сложный декор, роспись, акварельная техника, ягоды, фигурки ручной работы, пряники, сахарная бумага"
          />
        </Helmet>
        {decorationList.map((item) => (
          <DecorProduct item={item} key={item.id} />
        ))}
      </section>
      <Footer />
    </div>
  );
};
