import zavar_ponchik from './img/item/Тортопончики/Шоколадный.jpg'
import kokos_ponchik from './img/item/Тортопончики/Красный.jpg'
import apelsin_ponchik from './img/item/Тортопончики/Серый.jpg'
import jagoda_ponchik from './img/item/Тортопончики/Посыпанный.jpg'
import karamel_ponchik from './img/item/Тортопончики/Зелёный.jpg'

export const ponchikList = {
  meta: {
    title: "ДжоКонди | Тортопончики",
    description: "Тортопончики",
    keywords:
      "заварной крем, кокос, апельсин, ягодный конфитюр, вареная карамель",
  },
  productList: [
    {
      id: "p1",
      groupProduct: "Тортопончик",
      nameProduct: "с заварным кремом",
      img: zavar_ponchik,
      anot: ["Диаметр - 17 см", " Вес - 450-500 грамм"],
      price: 1000,
      unit: "шт.",
      minCount: 1, // Минимальное кол-во
      stepCount: 1, // Шаг прибавления
      discount: 0, //Скидка в %
    },
    {
      id: "p2",
      groupProduct: "Тортопончик",
      nameProduct: "с кокосовым заварным кремом",
      img: kokos_ponchik,
      anot: ["Диаметр - 17 см", " Вес - 450-500 грамм"],
      price: 1000,
      unit: "шт.",
      minCount: 1, // Минимальное кол-во
      stepCount: 1, // Шаг прибавления
      discount: 0, //Скидка в %
    },
    {
      id: "p3",
      groupProduct: "Тортопончик",
      nameProduct: "с апельсиновым конфитюром",
      img: apelsin_ponchik,
      anot: ["Диаметр - 17 см", " Вес - 450-500 грамм"],
      price: 1000,
      unit: "шт.",
      minCount: 1, // Минимальное кол-во
      stepCount: 1, // Шаг прибавления
      discount: 0, //Скидка в %
    },
    {
      id: "p4",
      groupProduct: "Тортопончик",
      nameProduct: "с ягодным конфитюром",
      img: jagoda_ponchik,
      anot: ["Диаметр - 17 см", " Вес - 450-500 грамм"],
      price: 1000,
      unit: "шт.",
      minCount: 1, // Минимальное кол-во
      stepCount: 1, // Шаг прибавления
      discount: 0, //Скидка в %
    },
    {
      id: "p5",
      groupProduct: "Тортопончик",
      nameProduct: "с варёной карамелью",
      img: karamel_ponchik,
      anot: ["Диаметр - 17 см", " Вес - 450-500 грамм"],
      price: 1000,
      unit: "шт.",
      minCount: 1, // Минимальное кол-во
      stepCount: 1, // Шаг прибавления
      discount: 0, //Скидка в %
    },
  ],
};
