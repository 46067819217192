import ganash_Beilis from "./img/item/Макаронсы/Ганаш Бейлис.jpg"
import ganash_Mokko from "./img/item/Макаронсы/Ганаш Мокко.jpg"
import klubnika_Bazelik from "./img/item/Макаронсы/Клубника-Базилик.jpg"
import laim_mjata from "./img/item/Макаронсы/Лайм-Мята.jpg"
import sol_karamel from "./img/item/Макаронсы/Солёная карамель.jpg"
import fistashka_malina from "./img/item/Макаронсы/Фисташка-Малина.jpg"
import black_smorodina from "./img/item/Макаронсы/Чёрная смородина.jpg"
import mango_marakuja from "./img/item/Макаронсы/Манго-Маракуйя.jpg"

export const makaronsList = {
  meta: {
    title: "ДжоКонди | Макаронсы",
    description: "Макаронсы",
    keywords:
      "ганаш, клубника-базилик, лайм-мята, солёная карамель, фисташка-малина, чёрная смородина, манго-маракуйя",
  },
    productList: [
        {
          id: "mak1",
          groupProduct: "Макаронс",
          nameProduct: '"Ганаш Бейлис"',
          img: ganash_Beilis,
          price: 130,
          unit: "шт.",
          minCount: 6, // Минимальное кол-во
          stepCount: 1, // Шаг прибавления
          discount: 0, //Скидка в %
        },
        {
            id: "mak2",
            groupProduct: "Макаронс",
            nameProduct: '"Ганаш Мокко"',
            img: ganash_Mokko,
            price: 130,
            unit: "шт.",
            minCount: 6, // Минимальное кол-во
            stepCount: 1, // Шаг прибавления
            discount: 0, //Скидка в %
          },
          {
            id: "mak3",
            groupProduct: "Макаронс",
            nameProduct: '"Клубника-Базилик"',
            img: klubnika_Bazelik,
            price: 130,
            unit: "шт.",
            minCount: 6, // Минимальное кол-во
            stepCount: 1, // Шаг прибавления
            discount: 0, //Скидка в %
          },
          {
            id: "mak4",
            groupProduct: "Макаронс",
            nameProduct: '"Лайм-Мята"',
            img: laim_mjata,
            price: 130,
            unit: "шт.",
            minCount: 6, // Минимальное кол-во
            stepCount: 1, // Шаг прибавления
            discount: 0, //Скидка в %
          },
          {
            id: "mak5",
            groupProduct: "Макаронс",
            nameProduct: '"Солёная карамель"',
            img: sol_karamel,
            price: 130,
            unit: "шт.",
            minCount: 6, // Минимальное кол-во
            stepCount: 1, // Шаг прибавления
            discount: 0, //Скидка в %
          },
          {
            id: "mak6",
            groupProduct: "Макаронс",
            nameProduct: '"Фисташка-Малина"',
            img: fistashka_malina,
            price: 130,
            unit: "шт.",
            minCount: 6, // Минимальное кол-во
            stepCount: 1, // Шаг прибавления
            discount: 0, //Скидка в %
          },
          {
            id: "mak7",
            groupProduct: "Макаронс",
            nameProduct: '"Чёрная смородина"',
            img: black_smorodina,
            price: 130,
            unit: "шт.",
            minCount: 6, // Минимальное кол-во
            stepCount: 1, // Шаг прибавления
            discount: 0, //Скидка в %
          },
          {
            id: "mak8",
            groupProduct: "Макаронс",
            nameProduct: '"Манго-Маракуйя"',
            img: mango_marakuja,
            price: 130,
            unit: "шт.",
            minCount: 6, // Минимальное кол-во
            stepCount: 1, // Шаг прибавления
            discount: 0, //Скидка в %
          },
]}