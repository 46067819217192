import rafaello_candy from './img/item/Конфеты/Рафаэлло.jpg'
import oreo_candy from './img/item/Конфеты/Орео.jpg'
import basilik_candy from './img/item/Конфеты/Клубника-Базелик.jpg'
import halva_candy from './img/item/Конфеты/Шоколадная халва.jpg'
import fistashka_candy from './img/item/Конфеты/Фисташка.jpg'
import funduk_candy from './img/item/Конфеты/Фундучное пралине.jpg'
import smorodina_candy from './img/item/Конфеты/Чёрная смородина.jpg'
import limon_candy from './img/item/Конфеты/Лимон-Мята.jpg'
import finik_candy from './img/item/Конфеты/Финик-Орех.jpg'
import drunk_cherry from './img/item/Конфеты/Пьяная вишня.jpg'
import oblepiha_appelsin from './img/item/Конфеты/Облепиха-Апельсин.jpg'


export const candyList = {
  meta: {
    title: "ДжоКонди | Конфеты",
    description: "Конфеты",
    keywords:
      "рафаэлло, клубника, орео, фисташка, фундучное, смородина, лимон, мята, финик, вишня, апельсин, пьяная вишня, облепиха",
  },
  productList: [
      {
        id: "candy1",
        groupProduct: "Конфета",
        nameProduct: '"Рафаэлло"',
        img: rafaello_candy,
        price: 100,
        unit: "шт.",
        minCount: 5, // Минимальное кол-во
        stepCount: 1, // Шаг прибавления
        discount: 0, //Скидка в %
      },
      {
        id: "candy2",
        groupProduct: "Конфета",
        nameProduct: '"Клубника-Базелик"',
        img: basilik_candy,
        price: 200,
        unit: "шт.",
        minCount: 5, // Минимальное кол-во
        stepCount: 1, // Шаг прибавления
        discount: 0, //Скидка в %
      },
      {
        id: "candy3",
        groupProduct: "Конфета",
        nameProduct: '"Орео"',
        img: oreo_candy,
        price: 170,
        unit: "шт.",
        minCount: 5, // Минимальное кол-во
        stepCount: 1, // Шаг прибавления
        discount: 0, //Скидка в %
      },
      {
        id: "candy4",
        groupProduct: "Конфета",
        nameProduct: '"Шоколадная халва"',
        img: halva_candy,
        price: 100,
        unit: "шт.",
        minCount: 5, // Минимальное кол-во
        stepCount: 1, // Шаг прибавления
        discount: 0, //Скидка в %
      },
      {
        id: "candy5",
        groupProduct: "Конфета",
        nameProduct: '"Фисташка"',
        img: fistashka_candy,
        price: 200,
        unit: "шт.",
        minCount: 5, // Минимальное кол-во
        stepCount: 1, // Шаг прибавления
        discount: 0, //Скидка в %
      },
      {
        id: "candy6",
        groupProduct: "Конфета",
        nameProduct: '"Фундучное пралине"',
        img: funduk_candy,
        price: 110,
        unit: "шт.",
        minCount: 5, // Минимальное кол-во
        stepCount: 1, // Шаг прибавления
        discount: 0, //Скидка в %
      },
      {
        id: "candy7",
        groupProduct: "Конфета",
        nameProduct: '"Чёрная смородина"',
        img: smorodina_candy,
        price: 155,
        unit: "шт.",
        minCount: 5, // Минимальное кол-во
        stepCount: 1, // Шаг прибавления
        discount: 0, //Скидка в %
      },
      {
        id: "candy8",
        groupProduct: "Конфета",
        nameProduct: '"Лимон-Мята"',
        img: limon_candy,
        price: 130,
        unit: "шт.",
        minCount: 5, // Минимальное кол-во
        stepCount: 1, // Шаг прибавления
        discount: 0, //Скидка в %
      },
      {
        id: "candy9",
        groupProduct: "Конфета",
        nameProduct: '"Финик-Орех"',
        img: finik_candy,
        price: 130,
        unit: "шт.",
        minCount: 5, // Минимальное кол-во
        stepCount: 1, // Шаг прибавления
        discount: 0, //Скидка в %
      },
      {
        id: "candy10",
        groupProduct: "Конфета",
        nameProduct: '"Пьяная вишня"',
        img: drunk_cherry,
        price: 155,
        unit: "шт.",
        minCount: 5, // Минимальное кол-во
        stepCount: 1, // Шаг прибавления
        discount: 0, //Скидка в %
      },
      {
        id: "candy11",
        groupProduct: "Конфета",
        nameProduct: '"Облепиха-Апельсин"',
        img: oblepiha_appelsin,
        price: 140,
        unit: "шт.",
        minCount: 5, // Минимальное кол-во
        stepCount: 1, // Шаг прибавления
        discount: 0, //Скидка в %
      },
]}