import React from "react";
import { setMenuActive } from "../redux/slices/menuActiveSlice";
import { useDispatch } from "react-redux";
import './style.css';

const LogoSvg = ({classSvg, classText, classEllipse, classPath}) => {

  const dispatch = useDispatch();
  return (
    <svg
      className={classSvg}
      onClick={() => dispatch(setMenuActive("/"))}
      viewBox="0 0 237.6 111.8"
      role="img"
    >
      <title>ДжоКонди</title>
      <desc>Кондитерская "ДжоКонди"</desc>
      <ellipse
        className={classEllipse}
        cx="118.8"
        cy="55.9"
        rx="118.3"
        ry="55.4"
        fill="none"
      />
      <path
        className={classPath}
        d="M118.8,1c65,0,117.8,24.6,117.8,54.9s-52.8,54.9-117.8,54.9S1,86.2,1,55.9S53.8,1,118.8,1 M118.8,0C53.2,0,0,25,0,55.9
        s53.2,55.9,118.8,55.9s118.8-25,118.8-55.9S184.4,0,118.8,0L118.8,0z"
        fill="none"
      />
      <text transform="matrix(1 0 0 1 10.833 70.6492)" className={classText}>
        ДжоКонди
      </text>
    </svg>
  );
};
export default LogoSvg;
