import React from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import DroopMenu from "../DroopMenu";
import { Search } from "../Search";
import { setMenuActive } from "../redux/slices/menuActiveSlice";

import "./style.css";
import LogoSvg from "../LogoSvg";

const Logo = () => {
  const dispatch = useDispatch();
  const toggleSearch = useSelector((state) => state.searchValue.toggleSearch);
  const item = useSelector((state) => state.item.item);
  const [openMenu, setOpenMenu] = React.useState(false);
  const refMenu = React.useRef(null);

  React.useEffect(() => {
    if (!openMenu) return;

    // Закрытие выпадающего меню по клику вне окна
    const handleCloseDroopMenu = (event) => {
      if (!refMenu.current.contains(event.target)) setOpenMenu(false);
    };
    // Установка прослушивателя
    document.addEventListener("click", handleCloseDroopMenu);
    // Удаление прослушивателя
    return () => {
      document.removeEventListener("click", handleCloseDroopMenu);
    };
  });

  return (
    <div className="logo__wrap">
      <Link to="/">
        <LogoSvg
          classSvg="logo__img"
          classText="logo__text"
          classEllipse="logo__ellipse"
          classPath="logo__path"
        />
      </Link>
      <div className="search_with_mobile">
        {toggleSearch && <Search />}
        <div className="mobile__wrap">
          <svg className="mobile__svg" viewBox="0 0 38 40" role="img">
            <title>Телефон</title>
            <desc>Мобильный телефон</desc>
            <path
              d="M9.29127 8.24C10.7149 6.88 12.738 6.64 14.3865 7.44C14.5363 7.52 14.7611 7.6 14.911 7.68L15.5854 16.24C14.8361 16.88 14.0118 17.28 13.1127 17.36C13.5623 19.28 14.4614 21.04 15.7352 22.64C17.0839 24.32 18.7324 25.52 20.5307 26.32C20.7555 25.44 21.28 24.64 21.9544 24L22.0293 23.92L29.5223 25.84C29.5972 26 29.6721 26.16 29.6721 26.32C30.2715 28.16 29.822 30.32 28.3983 31.76C26.2254 32.24 23.9025 32.24 21.7296 31.76C18.133 31.04 14.7611 29.04 12.2135 25.84C9.59098 22.64 8.31718 18.72 8.16732 14.8C8.09239 12.72 8.39211 10.64 8.99155 8.63999C8.99155 8.55999 9.14141 8.4 9.29127 8.24Z"
              strokeMiterlimit="10"
            />
          </svg>
          <a className="mobile__phone" href="tel:+79173851463">
            +7(917)385-14-63
          </a>
        </div>
      </div>
      <Link className="logo__basket" to="/basket">
        <svg
          onClick={() => dispatch(setMenuActive("/basket"))}
          viewBox="0 0 37 44"
          xmlns="http://www.w3.org/2000/svg"
        >
          <title>Козина</title>
          <desc>Корзина товаров</desc>
          <path
            d="M28.6801 11.54H8.01006C6.40006 11.54 5.04006 12.75 4.85006 14.35L2.08006 38.12C1.86006 40.01 3.34006 41.67 5.24006 41.67H31.3601C33.2601 41.67 34.7301 40.02 34.5201 38.13L31.8301 14.36C31.6501 12.75 30.2901 11.54 28.6701 11.54H28.6801Z"
            strokeWidth="3.5"
            strokeMiterlimit="10"
          />
          <path
            d="M10.1001 22.16V8.05001C10.1001 7.40001 10.2801 6.76001 10.6301 6.22001C11.5801 4.74001 13.8801 2.07001 18.1301 2.07001C22.3801 2.07001 24.5501 4.43001 25.5201 5.82001C25.9101 6.38001 26.1001 7.05001 26.1001 7.73001V21.78"
            strokeWidth="3.5"
            strokeMiterlimit="10"
          />
        </svg>
        {item.length > 0 ? (
          <div className="basket__number">{item.length}</div>
        ) : null}
      </Link>

      {/* Кнопка бургер */}
      <div
        ref={refMenu}
        className={openMenu ? "droopMenu__btn open" : "droopMenu__btn"}
        onClick={() => setOpenMenu(!openMenu)}
      >
        <div className="btn__wrap">
          <span className="btn__span"></span>
        </div>
        {/* Выпадающее меню */}
        <DroopMenu openMenu={openMenu} />
      </div>
    </div>
  );
};

export default Logo;
