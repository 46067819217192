import React from "react";
import { Link } from "react-router-dom";
import "./style.css";
import LogoSvg from "../LogoSvg";

const Footer = () => {
  return (
    <section className="footer container">
      <Link to="/">
      <LogoSvg classSvg="logo__img_footer" classText="logo__text_footer" classEllipse="logo__ellipse_footer" classPath="logo__path_footer"/>
      </Link>
      <div className="footer__mobile_and_mail">
        <div className="footer__mobile">
          <a href="tel:+79173851463">
            <div className="footer__mobile_wrap">
              <svg viewBox="0 0 38 40" role='img'>
              <title>Телефон</title>
              <desc>Мобильный телефон</desc>
                <path d="M9.29127 8.24C10.7149 6.88 12.738 6.64 14.3865 7.44C14.5363 7.52 14.7611 7.6 14.911 7.68L15.5854 16.24C14.8361 16.88 14.0118 17.28 13.1127 17.36C13.5623 19.28 14.4614 21.04 15.7352 22.64C17.0839 24.32 18.7324 25.52 20.5307 26.32C20.7555 25.44 21.28 24.64 21.9544 24L22.0293 23.92L29.5223 25.84C29.5972 26 29.6721 26.16 29.6721 26.32C30.2715 28.16 29.822 30.32 28.3983 31.76C26.2254 32.24 23.9025 32.24 21.7296 31.76C18.133 31.04 14.7611 29.04 12.2135 25.84C9.59098 22.64 8.31718 18.72 8.16732 14.8C8.09239 12.72 8.39211 10.64 8.99155 8.63999C8.99155 8.55999 9.14141 8.4 9.29127 8.24Z" />
              </svg>
              <div className="footer__mobile_number">+7 917 385 14 63</div>
            </div>
          </a>
        </div>
        <div className="mail">
          <a href="mailto:yanguzel2008@yandex.ru">
            <div className="mail__wrap">
              <div>
                <svg viewBox="0 0 35 35" role="img">
                <title>Почта</title>
                <desc>Отправить письмо на почту</desc>
                  <path d="M26.52 8.88002V21.12C26.52 22.2 25.62 23.1 24.54 23.1H5.45998C4.37998 23.1 3.47998 22.2 3.47998 21.12V8.88002C3.47998 8.40002 3.65998 7.98002 3.89998 7.68002C4.25998 7.20002 4.79998 6.90002 5.45998 6.90002H24.54C25.02 6.90002 25.5 7.08002 25.86 7.44002C26.28 7.80002 26.52 8.34002 26.52 8.88002Z" />
                  <path d="M25.8599 7.44002L18.6599 15.3C17.0399 17.1 14.2799 17.22 12.4799 15.54L3.8999 7.68002C4.2599 7.20002 4.7999 6.90002 5.4599 6.90002H24.5399C25.0799 6.90002 25.4999 7.14002 25.8599 7.44002Z" />
                </svg>
              </div>
              <div className="mail__adress">yan_guzel2008@yandex.ru</div>
            </div>
          </a>
        </div>
      </div>
      <div className="footer__social">
        <div className="watsapp">
          <a href="https://wa.me/79173851463">
            <svg viewBox="0 0 35 35" role="img">
            <title>Watsapp</title>
            <desc>Мы в Watsapp</desc>
              <path d="M30.3281 15.028C30.3281 22.372 24.3441 28.356 17.0001 28.356C14.4841 28.356 12.1721 27.676 10.2001 26.52L2.92407 28.832L4.76007 20.332C4.08007 18.7 3.67207 16.932 3.67207 15.028C3.67207 7.61602 9.65607 1.63202 17.0001 1.63202C24.3441 1.63202 30.3281 7.61602 30.3281 15.028Z" />
              <path d="M10.6079 7.00403C11.5599 6.12003 12.9199 5.98403 14.0759 6.46003C14.2119 6.52803 14.2799 6.59603 14.4159 6.66403L14.8919 12.172C14.4159 12.58 13.8039 12.852 13.1919 12.92C13.5319 14.144 14.0759 15.3 14.9599 16.32C15.9119 17.408 17.0679 18.224 18.2919 18.7C18.4959 18.156 18.8359 17.612 19.3119 17.204L19.3799 17.136L24.5479 18.36C24.6159 18.428 24.6159 18.564 24.6839 18.632C25.0919 19.856 24.7519 21.216 23.7999 22.1C22.3039 22.44 20.6719 22.44 19.1759 22.1C16.7279 21.624 14.3479 20.332 12.5799 18.292C10.8119 16.184 9.85994 13.668 9.79194 11.152C9.72394 9.79203 9.92794 8.43203 10.3359 7.14003C10.4039 7.14003 10.4719 7.07203 10.6079 7.00403Z" />
            </svg>
          </a>
        </div>
        <div className="telegramm">
          <a href="https://t.me/ynguz">
            <svg viewBox="0 0 35 35" role="img">
            <title>Телеграмм</title>
            <desc>Страница в телеграмм канале</desc>
              <path d="M9.9961 17.136L1.5641 14.892C1.1561 14.824 1.1561 14.28 1.4961 14.144L31.0081 2.72003C31.2801 2.58403 31.6201 2.85603 31.5521 3.19603L26.1801 28.084C26.1121 28.356 25.7721 28.492 25.5681 28.288L17.8841 22.44C17.7481 22.304 17.4761 22.304 17.3401 22.44L13.6001 26.18C13.3281 26.452 12.9201 26.248 12.9201 25.84L13.3961 19.176C13.3961 19.04 13.4641 18.972 13.5321 18.904L24.2081 9.31603C24.5481 8.97603 24.1401 8.43203 23.7321 8.70403L10.2681 17.136C10.2001 17.204 10.0641 17.204 9.9961 17.136Z" />
            </svg>
          </a>
        </div>
        <div className="vk">
          <a href="https://vk.com/id29695459">
            <svg viewBox="0 0 35 22" role="img">
            <title>В контакте</title>
            <desc>Страница в Вконтакте</desc>
              <path d="M2.14107 1.43891H6.6606C6.6606 1.43891 7.42988 1.43891 7.81452 2.97747C8.19916 4.51604 11.8533 12.2089 13.5841 12.1127C13.5841 12.1127 14.0649 12.1127 14.1611 11.3434C14.1611 10.7665 14.1611 7.59316 14.1611 5.86228C14.1611 5.18916 13.9688 4.51604 13.6803 3.93907C13.3918 3.45827 13.1033 2.88131 12.8149 2.40051C12.5264 1.91971 12.4302 1.43891 13.488 1.43891C14.9304 1.43891 19.6422 1.43891 19.6422 1.43891C19.6422 1.43891 20.123 1.24659 20.123 2.30435C20.123 3.36211 20.2192 10.0933 20.2192 10.0933C20.2192 10.0933 20.7 11.0549 21.3731 10.3818C22.1424 9.70869 25.8927 4.6122 26.8543 2.01587C26.8543 2.01587 27.1427 1.43891 28.1043 1.43891C29.0659 1.43891 32.2392 1.43891 32.2392 1.43891C32.2392 1.43891 33.1047 1.72739 32.72 2.68899C32.3354 3.65059 28.0082 10.5741 27.8159 11.1511C27.7197 11.4396 27.912 11.7281 27.912 11.7281C27.912 11.7281 33.1047 17.4977 33.3932 18.8439C33.5855 19.6132 33.5855 19.9978 32.8162 20.3825C32.72 20.4786 29.6429 20.5748 28.6813 20.5748C28.3928 20.5748 28.2005 20.4786 28.0082 20.2863C26.8543 19.0362 22.0462 14.9975 21.7578 14.709C21.5654 14.5167 20.3154 13.9397 20.3154 15.286C20.3154 16.3437 20.2192 18.0746 20.2192 18.9401C20.2192 19.3247 20.0269 19.7094 19.6422 19.9978C19.4499 20.1902 19.1614 20.2863 18.7768 20.2863C16.9497 20.3825 16.7574 20.3825 16.2766 20.2863C16.0843 20.1902 6.56444 17.9785 1.56411 2.11203C1.46795 2.20819 1.37179 1.53507 2.14107 1.43891Z" />
            </svg>
          </a>
        </div>
        <div className="instagramm">
          <a href="https://www.instagram.com/guzel_yanborisova?igsh=MWg5Z3V0a2s1YzY2bA==">
            <svg viewBox="0 0 39 39" role="img">
            <title>Инстаграмм</title>
            <desc>Страница в инстаграмм</desc>
              <path d="M29.0683 36.6795H9.61118C5.41073 36.6795 2 33.2688 2 29.0683V9.61118C2 5.41073 5.41073 2 9.61118 2H29.0683C33.2688 2 36.6795 5.41073 36.6795 9.61118V29.0683C36.6795 33.2688 33.2688 36.6795 29.0683 36.6795Z" />
              <path d="M19.5802 27.6606C23.8912 27.6606 27.3859 24.1658 27.3859 19.8548C27.3859 15.5438 23.8912 12.0491 19.5802 12.0491C15.2692 12.0491 11.7744 15.5438 11.7744 19.8548C11.7744 24.1658 15.2692 27.6606 19.5802 27.6606Z" />
              <path d="M29.4689 11.1563C30.5435 11.1563 31.4146 10.2852 31.4146 9.21061C31.4146 8.13602 30.5435 7.26489 29.4689 7.26489C28.3943 7.26489 27.5232 8.13602 27.5232 9.21061C27.5232 10.2852 28.3943 11.1563 29.4689 11.1563Z" />
            </svg>
          </a>
        </div>
      </div>
      <div className="yanmail">
        <a href="mailto:yan_ilgiz@mail.ru">
          <div className="yanmail_text">Разработка сайтов</div>
        </a>
        <div className="yanmail_text_version">v2_130724</div>
      </div>
    </section>
  );
};

export default Footer;
