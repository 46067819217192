import multi from "./img/item/Торты на свадьбу и юбилей/Многоярусный торт с Гузель.jpg";
import wedding1 from "./img/item/Торты на свадьбу и юбилей/Свадебный.jpg";
import children from "./img/item/Торты на свадьбу и юбилей/Детские сны.jpg";
import provans from "./img/item/Торты на свадьбу и юбилей/Вид с окна.jpg";
import illusion from "./img/item/Торты на свадьбу и юбилей/Бабочки.jpg";
import sea from "./img/item/Торты на свадьбу и юбилей/Ракушки.jpg";
import jubilee1 from "./img/item/Торты на свадьбу и юбилей/С Днем рождения.jpg";
import wedding2 from "./img/item/Торты на свадьбу и юбилей/КИ.jpg";
import jubilee2 from "./img/item/Торты на свадьбу и юбилей/50 лет.jpg";
import jubilee3 from "./img/item/Торты на свадьбу и юбилей/Парусник.jpg";
import wedding4 from "./img/item/Торты на свадьбу и юбилей/Свадебный2.jpg";

export const multiList = {
  meta: {
    title: "ДжоКонди | Многоярусные",
    description: "Многоярусные торты",
    keywords:
      "свадьба, юбилей, многоярусный, день рождения",
  },
  productList: [
    {
      id: "mult1",
      groupProduct: "Торт бисквитный",
      nameProduct: '"Многоярусный"',
      img: multi,
      price: 2000,
      unit: "кг.",
      minCount: 2, // Минимальное кол-во
      stepCount: 0.5, // Шаг прибавления
      discount: 0, //Скидка в %
    },
    {
      id: "mult2",
      groupProduct: "Торт бисквитный",
      nameProduct: '"Свадебный"',
      img: wedding1,
      price: 2000,
      unit: "кг.",
      minCount: 2, // Минимальное кол-во
      stepCount: 0.5, // Шаг прибавления
      discount: 0, //Скидка в %
    },
    {
      id: "mult3",
      groupProduct: "Торт бисквитный",
      nameProduct: '"Детские сны"',
      img: children,
      price: 2000,
      unit: "кг.",
      minCount: 2, // Минимальное кол-во
      stepCount: 0.5, // Шаг прибавления
      discount: 0, //Скидка в %
    },
    {
      id: "mult4",
      groupProduct: "Торт бисквитный",
      nameProduct: '"Прованс"',
      img: provans,
      price: 2000,
      unit: "кг.",
      minCount: 2, // Минимальное кол-во
      stepCount: 0.5, // Шаг прибавления
      discount: 0, //Скидка в %
    },
    {
      id: "mult5",
      groupProduct: "Торт бисквитный",
      nameProduct: '"Иллюзия"',
      img: illusion,
      price: 2000,
      unit: "кг.",
      minCount: 2, // Минимальное кол-во
      stepCount: 0.5, // Шаг прибавления
      discount: 0, //Скидка в %
    },
    {
      id: "mult6",
      groupProduct: "Торт бисквитный",
      nameProduct: '"Морской"',
      img: sea,
      price: 2000,
      unit: "кг.",
      minCount: 2, // Минимальное кол-во
      stepCount: 0.5, // Шаг прибавления
      discount: 0, //Скидка в %
    },
    {
      id: "mult7",
      groupProduct: "Торт бисквитный",
      nameProduct: '"На юбилей"',
      img: jubilee1,
      price: 2000,
      unit: "кг.",
      minCount: 2, // Минимальное кол-во
      stepCount: 0.5, // Шаг прибавления
      discount: 0, //Скидка в %
    },
    {
      id: "mult8",
      groupProduct: "Торт бисквитный",
      nameProduct: '"Свадебный"',
      img: wedding2,
      price: 2000,
      unit: "кг.",
      minCount: 2, // Минимальное кол-во
      stepCount: 0.5, // Шаг прибавления
      discount: 0, //Скидка в %
    },
    {
      id: "mult9",
      groupProduct: "Торт бисквитный",
      nameProduct: '"На юбилей"',
      img: jubilee2,
      price: 2000,
      unit: "кг.",
      minCount: 2, // Минимальное кол-во
      stepCount: 0.5, // Шаг прибавления
      discount: 0, //Скидка в %
    },
    {
      id: "mult11",
      groupProduct: "Торт бисквитный",
      nameProduct: '"На юбилей"',
      img: jubilee3,
      price: 2000,
      unit: "кг.",
      minCount: 2, // Минимальное кол-во
      stepCount: 0.5, // Шаг прибавления
      discount: 0, //Скидка в %
    },
    {
      id: "mult12",
      groupProduct: "Торт бисквитный",
      nameProduct: '"Свадебный"',
      img: wedding4,
      price: 2000,
      unit: "кг.",
      minCount: 2, // Минимальное кол-во
      stepCount: 0.5, // Шаг прибавления
      discount: 0, //Скидка в %
    },
  ],
};
