import React from "react";
import { Helmet } from "react-helmet";
import Footer from "../../Footer";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { BasketProduct } from "../BasketProduct";

import PopapForm from "../../PopapForm";

import "./style.css";
import { clearBasket } from "../../redux/slices/basketSlice";

export const BasketPage = () => {
  const dispatch = useDispatch();
  const delivery = 300; //_________________________________________Доставка
  const [popap, setPopap] = React.useState(false);
  const [check, setCheck] = React.useState(
    JSON.parse(localStorage.getItem("delivery")) || false
  );
  const [messagePost, setMessagePost] = React.useState('');
  const [agreement, setAgreement] = React.useState(true);
  const [userName, setUserName] = React.useState("");
  const [sending, setSending] = React.useState(false);

  const item = useSelector((state) => state.item.item);

  localStorage.setItem("delivery", JSON.stringify(check));

  const totalPrice = item.reduce(
    (acc, item) => acc + item.totalPrice + item.priceDecor,
    0
  );

  const totalPriceDelivery = check ? totalPrice + delivery : totalPrice;

  // Закрытие сообщения об отправке--------------------------------------------------------------------------
  React.useEffect(() => {
    if (!messagePost) return;

    // Закрытие окна сообщения по клику
    const handleCloseMessagePost = () => {
      setMessagePost(false);
    };

    // Закрытие окна сообщения по нажатию клавиши "Escape"
    const handleKeyEscapeCloseMessage = (event) => {
      if (event.key === "Escape") setMessagePost(false);
    };

    // Установка прослушивателей
    document.addEventListener("keydown", handleKeyEscapeCloseMessage);
    document.addEventListener("click", handleCloseMessagePost);

    // Удаление прослушивателей
    return () => {
      document.removeEventListener("keydown", handleKeyEscapeCloseMessage);
      document.removeEventListener("click", handleCloseMessagePost);
    };
  }, [messagePost]);

  return (
    <div className="container wrap">
       <Helmet>
          <title>ДжоКонди | Корзина</title>
          <meta name="description" content="Корзина товаров" />
          <meta
            name="keywords"
            content="доставка тортов, оплата"
          />
        </Helmet>
      <section className="basket">
        {item.length > 0 ? (
          <>
            <h1 className="basket__title">Корзина товаров</h1>
            <button
              className="basket__clear"
              onClick={() => dispatch(clearBasket())}
            >
              Очистить корзину
            </button>
            <div className="basket__wrap">
              {item.map((product) => (
                <BasketProduct key={product.id} {...product} />
              ))}
            </div>
            <div className="basket__cost">
              <div className="checkbox" onClick={() => setCheck(!check)}>
                <div
                  className={
                    check
                      ? "checkbox__item checkbox__item--active"
                      : "checkbox__item"
                  }
                ></div>
                <p className="checkbox__text">
                  {`Доставка по г.Уфа (${delivery} руб.)`}
                </p>
              </div>
              <div className="checkbox__text-warning">
                <Link to="/delivery">
                  (Внимательно ознакомьтесь с правилами доставки!)
                </Link>
              </div>
              <h3 className="basket__totalPrice">
                Общая стоимость заказа:
                <span>{totalPriceDelivery.toLocaleString()}</span> руб.
              </h3>
              <div className="basket__btn-wrap">
                <button
                  className={
                    agreement ? "basket__btn" : "basket__btn btn__disabled"
                  }
                  onClick={() => setPopap(!popap)}
                  disabled={!agreement}
                >
                  Отправить заказ
                </button>
              </div>

              <div
                className="basket__agreement"
                onClick={() => setAgreement(!agreement)}
              >
                <div
                  className={
                    agreement
                      ? "checkbox__item checkbox__item--active"
                      : "checkbox__item"
                  }
                ></div>
                <div className="basket__agreement_text">
                  <div>Принимаю условия</div>
                  <div>
                    <Link to="/agreement"> соглашения</Link>
                  </div>
                </div>
              </div>
            </div>
            <div
              className={
                popap ? "basket__popap basket__popap--active" : "basket__popap"
              }
            ></div>
            <div
              className={
                messagePost
                  ? "basket__popap basket__popap--active"
                  : "basket__popap"
              }
            ></div>
            {popap && (
              <PopapForm
                check={check}
                delivery={delivery}
                totalPrice={totalPrice}
                item={item}
                popap={popap}
                setPopap={setPopap}
                setMessagePost={setMessagePost}
                userName={userName}
                setUserName={setUserName}
                setSending={setSending}
              />
            )}
            {sending && (
              <div className="messagePost">
                <p>Идёт отправка заказа...</p>
              </div>
            )}

            {messagePost === 'success'? (
              <div className="messagePost">
                <p>{`${userName} cпасибо за заказ.`}</p>
                <p>В ближайшее время мы свяжемся с Вами!</p>
              </div>
            ) : messagePost === 'error'? (
              <div className="messagePost">
                <p>Сообщение не отправлено, попробуйте ещё раз</p>
              </div>
            ) : null}
          </>
        ) : (
          <>
            <h1 className="basket__title">Ваша корзина пустая </h1>
            <p className="basket__smail">&#128533;</p>
          </>
        )}
      </section>
      <Footer />
    </div>
  );
};
