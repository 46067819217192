import ananas_apelsin from './img/item/Зефиры, Безе/Ананас-Апельсин-Грецкий орех.jpg'
import vishnja_Tonka from './img/item/Зефиры, Безе/Вишня-Тонка.jpg'
import klubnika_zefir from './img/item/Зефиры, Безе/Клубничный.jpg'
import kofe_zefir from './img/item/Зефиры, Безе/Кофейный.jpg'
import malina_zefir from './img/item/Зефиры, Безе/Малиновый.jpg'
import mango_zefir from './img/item/Зефиры, Безе/Манго.jpg'
import sliva_zefir from './img/item/Зефиры, Безе/Сливовый.jpg'
import Pavlova_beze from './img/item/Зефиры, Безе/Безе Павлова.jpg'
import grusha_zefir from './img/item/Зефиры, Безе/Грушевый.jpg'

export const zefirList = {
  meta: {
    title: "ДжоКонди | Зефир и безе",
    description: "Зефир и безе",
    keywords:
      "ананас, апельсин, грецкий орех, вишня-тонка, клубника, кофейник, малина, манго, слива, груша",
  },
    productList: [
        {
          id: "z1",
          groupProduct: "Зефир",
          nameProduct: '"Ананас-Апельсин-Грецкий орех"',
          img: ananas_apelsin,
          price: 70,
          unit: "шт.",
          minCount: 6, // Минимальное кол-во
          stepCount: 1, // Шаг прибавления
          discount: 0, //Скидка в %
        },
        {
            id: "z2",
            groupProduct: "Зефир",
            nameProduct: '"Вишня-Тонка"',
            img: vishnja_Tonka,
            price: 70,
            unit: "шт.",
            minCount: 6, // Минимальное кол-во
            stepCount: 1, // Шаг прибавления
            discount: 0, //Скидка в %
          },
          {
            id: "z3",
            groupProduct: "Зефир",
            nameProduct: '"Клубничный"',
            img: klubnika_zefir,
            price: 70,
            unit: "шт.",
            minCount: 6, // Минимальное кол-во
            stepCount: 1, // Шаг прибавления
            discount: 0, //Скидка в %
          },
          {
            id: "z4",
            groupProduct: "Зефир",
            nameProduct: '"Кофейный"',
            img: kofe_zefir,
            price: 70,
            unit: "шт.",
            minCount: 6, // Минимальное кол-во
            stepCount: 1, // Шаг прибавления
            discount: 0, //Скидка в %
          },
          {
            id: "z5",
            groupProduct: "Зефир",
            nameProduct: '"Малиновый"',
            img: malina_zefir,
            price: 70,
            unit: "шт.",
            minCount: 6, // Минимальное кол-во
            stepCount: 1, // Шаг прибавления
            discount: 0, //Скидка в %
          },
          {
            id: "z6",
            groupProduct: "Зефир",
            nameProduct: '"Манго"',
            img: mango_zefir,
            price: 70,
            unit: "шт.",
            minCount: 6, // Минимальное кол-во
            stepCount: 1, // Шаг прибавления
            discount: 0, //Скидка в %
          },
          {
            id: "z7",
            groupProduct: "Зефир",
            nameProduct: '"Сливовый"',
            img: sliva_zefir,
            price: 70,
            unit: "шт.",
            minCount: 6, // Минимальное кол-во
            stepCount: 1, // Шаг прибавления
            discount: 0, //Скидка в %
          },
          {
            id: "z8",
            groupProduct: "Зефир",
            nameProduct: '"Грушевый"',
            img: grusha_zefir,
            price: 70,
            unit: "шт.",
            minCount: 6, // Минимальное кол-во
            stepCount: 1, // Шаг прибавления
            discount: 0, //Скидка в %
          },
          {
            id: "z9",
            groupProduct: "Безе",
            nameProduct: '"Павлова"',
            img: Pavlova_beze,
            price: 200,
            unit: "шт.",
            minCount: 4, // Минимальное кол-во
            stepCount: 1, // Шаг прибавления
            discount: 0, //Скидка в %
          },
          
]}