import { Route, Routes } from "react-router-dom";

import "./styles/reset.css";
import "./styles/main.css";

import { ProductPage } from "./Pages/ProductPage";
import { DecorPage } from "./Pages/Decor/DecorPage";
import { BasketPage } from "./Pages/BasketPage";
import { MainPage } from "./Pages/MainPage";
import { Header } from "./Header";
import { biscuitList } from "./dataPageBiscuit";
import { mussList } from "./dataPageMuss";
import { bentoList } from "./dataPageBento";
import { multiList } from "./dataPageMulti";
import { tartsList } from "./dataTarts";
import { mussCakesList } from "./dataMussCakes";
import { makaronsList } from "./dataMakarons";
import { zefirList } from "./dataZefir";
import { ponchikList } from "./dataPonchik";
import {candyList} from "./dataCandy";
import { DeliveryPage } from "./Pages/DeliveryPage";
import AgreementPage from "./Pages/AgreementPage";
import DecorList from "./Pages/Decor/DecorList";
import { basicDecor } from "./dataDecor/basicDecor";
import { bentoDecor } from "./dataDecor/bentoDecor";
import { isomaltDecor } from "./dataDecor/isomaltDecor";
import { ballDecor } from "./dataDecor/ballDecor";
import { flowersDecor } from "./dataDecor/flowersDecor";
import { paintingDecor } from "./dataDecor/paintingDecor";
import { sugarDecor } from "./dataDecor/sugarDecor";
import { hardDecor } from "./dataDecor/hardDecor";
import { aquarelleDecor } from './dataDecor/aquarelleDecor';
import { berryDecor } from './dataDecor/berryDecor';
import {cakeDecor} from "./dataDecor/cakeDecor";
import {handDecor} from './dataDecor/handDecor';
import { NotFound } from "./Pages/NotFound";

export default function App() {
  return (
    <div className="App">
      <Header />
      <Routes>
        <Route path="/" element={<MainPage />} />
        <Route path="/biscuit" element={<ProductPage productList={biscuitList} />}/>
        <Route path="/muss" element={<ProductPage productList={mussList} />} />
        <Route path="/bento" element={<ProductPage productList={bentoList} />} />
        <Route path="/multi" element={<ProductPage productList={multiList} />} />
        <Route path="/tarts" element={<ProductPage productList={tartsList} />} />
        <Route path="/cakes" element={<ProductPage productList={mussCakesList} />} />
        <Route path="/makarons" element={<ProductPage productList={makaronsList} />} />
        <Route path="/zefir" element={<ProductPage productList={zefirList} />} />
        <Route path="/ponchiki" element={<ProductPage productList={ponchikList} />} />
        <Route path="/candy" element={<ProductPage productList={candyList} />} />
        <Route path="/decor" element={<DecorPage />} />
              <Route path="/decor/basic" element={<DecorList decorItem={basicDecor} />} />
              <Route path="/decor/bento" element={<DecorList decorItem={bentoDecor} />} />
              <Route path="/decor/isomalt" element={<DecorList decorItem={isomaltDecor} />} />
              <Route path="/decor/ball" element={<DecorList decorItem={ballDecor} />} />
              <Route path="/decor/flowers" element={<DecorList decorItem={flowersDecor} />} />
              <Route path="/decor/painting" element={<DecorList decorItem={paintingDecor} />} />
              <Route path="/decor/sugar" element={<DecorList decorItem={sugarDecor} />} />
              <Route path="/decor/hard" element={<DecorList decorItem={hardDecor} />} />
              <Route path="/decor/aquarelle" element={<DecorList decorItem={aquarelleDecor} />} />
              <Route path="/decor/berry" element={<DecorList decorItem={berryDecor} />} />
              <Route path="/decor/cake" element={<DecorList decorItem={cakeDecor} />} />
              <Route path="/decor/hand" element={<DecorList decorItem={handDecor} />} />
        <Route path="/delivery" element={<DeliveryPage />} />
        <Route path="/basket" element={<BasketPage />} />
        <Route path="/agreement" element={<AgreementPage />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </div>
  );
}
