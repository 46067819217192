import Footer from "../../Footer";
import { Helmet } from "react-helmet";
import "./style.css";

export const DeliveryPage = () => {
  window.scrollTo(0, 0);
  return (
    <div className="container wrap">
      <Helmet>
          <title>ДжоКонди | Доставка</title>
          <meta name="description" content="Доставка и оплата" />
          <meta
            name="keywords"
            content="доставка тортов, оплата"
          />
        </Helmet>
      <section className="delivery">
        <div className="delivery__wrap">
          <h1 className="delivery__title">Информация о доставке</h1>
          <p className="delivery__client">Уважаемый клиент!</p>
          <p className="delivery__text">
            Кондитерская <b>"ДжоКонди"</b> сделает все возможное, чтобы Ваш
            заказ был доставлен вовремя, в согласованное Вами время.
          </p>
          <p className="delivery__text">
            Cкорость доставки зависит от многих факторов, в том числе от
            загруженности дорог г. Уфы.
          </p>
          <p className="delivery__text">
            Доставка осуществляется лично (не сторонний курьер) в будние,
            выходные и праздничные дни.
          </p>
          <p className="delivery__text">
            Доставка на территорию с платным въездом и/или парковкой
            осуществляется только до места платного въезда и/или парковки.
          </p>
          <p className="delivery__text">
            Доставка многоярусных тортов осуществляется по городу бесплатно, за
            пределами города рассчитывается индивидуально в зависимости от места
            проведения банкета.
          </p>
          <ul className="delivery__text">Доставка не осуществляется:</ul>
          <li className="delivery__text">
            В любое место без адреса, на стройплощадки, вокзалы, в транспортные
            компании, садово-огородные товарищества, цеха, пункты общественного
            питания (за исключением их административных помещений), режимные,
            секретные и несуществующие объекты.
          </li>
          <li className="delivery__text">
            В парки, лесные массивы, на пляжи или иные места, не имеющие
            определенного адреса, либо условий для передачи товаров, получения
            оплаты, а также, если проезд к месту передачи товара невозможен на
            расстояние более чем 100 метров.
          </li>
          <li className="delivery__text">По дорогам с нетвердым покрытием.</li>
          <li className="delivery__text">
            В случае, если с клиентом не удалось связаться.
          </li>
          <p className="delivery__text-price">
            Стоимость доставки кондитерских изделий по г. Уфа - 300 руб., за
            пределами города рассчитывается индивидуально в зависимости от
            адреса доставки.
          </p>
          <h1 className="delivery__title">Условия заказа и оплата</h1>
          <p className="delivery__text">
            Заказы принимаются минимум за 2-3 дня до предполагаемой даты
            доставки.
          </p>
          <p className="delivery__text">
            Обращаем Ваше внимание, что изменения в заказ можно внести не
            позднее, чем за 3 дня до назначенной даты доставки.
          </p>
          <p className="delivery__text">
            Отмена заказа принимается за 3 дня до даты доставки.
          </p>
          <p className="delivery__text">
            {" "}
            При отмене заказа менее чем за 3 дня до даты доставки - предоплата
            не возвращается.
          </p>
          <p className="delivery__text">
            Заказы принимаются на условиях предоплаты в размере 20% от стоимости
            заказа.
          </p>
        </div>
      </section>
      <Footer />
    </div>
  );
};
