import React from "react";
import Footer from '../../Footer'
import { useSelector, useDispatch } from "react-redux";
import { setToggleSearch } from "../../redux/slices/searchSlice";

import { Product } from "../Product";

import "./style.css";

export const ProductPage = ({ productList }) => {
  const searchValue = useSelector((state) => state.searchValue.searchValue);
  const dispatch = useDispatch();

  window.scrollTo(0, 0);

  React.useEffect(() => {
    dispatch(setToggleSearch(true));
    return () => dispatch(setToggleSearch(false));
  });

  return (
    <div className="container wrap">
      <section className="item">
        {productList.productList
          .filter((product) => {
            if (
              product.nameProduct
                .toLowerCase()
                .includes(searchValue.toLowerCase())
            )
              return true;
            else return false;
          })
          .map((product) => (
            <Product
              key={product.id}
              product={product}
              decorList={productList.decorList}
              meta={productList.meta}
            />
          ))}
      </section>
      <Footer />
    </div>
  );
};
